.festividadContainer .festih2{
   font-weight: bolder;
}
.festividadContainer{
   padding: 10px;
   color: #0C135A;
   display: flex;
   justify-content: center;
   flex-direction: column;

}
.festividadContainer .festih2{
   padding-bottom: 1rem;
}

 .festividadContainer p, .festividadContainer h4 {
     background-color: rgba(0,0,0, .1);
     width: 100%;
     max-width: 800px;
     margin: 4px auto;
     font-weight: bolder;
 }

 .festividadContainer p{
   text-align: justify;
   padding: 10px;
 }
 
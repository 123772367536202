.contenedorTexto{
   width:17em;
   border-radius: 10px;
   box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
   overflow: hidden;
   margin: 2rem;
   text-align: center;
   transition: all 0.25s;
   background-color: rgb(245,245,245);
   color: black;
}
.contenedorCajas{
   display: flex;
   justify-content: space-evenly;
   flex-wrap: wrap;
}
.contenedorTexto:hover{
   transform: translateY(-3px);
   box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.6);
}
.contenedorImagen{
   /*width: 100%;
   height: 25rem;*/
   max-width: 100%;
   max-height: 100%;
   height: auto;
}
.contenedorTitulo{
   font-weight: bolder;
   font-size: 1.4em;
   margin: 0.5em;
}
.contenedorParrafo{
   padding-left: 0.5em;
   padding-right: 0.5em;
   font-size: 1em;
   font-weight: 300;
   margin: 0.5em;
}
.botonMasInfo{
   margin: 0.3em 0.5em 1em 0.5em;
}
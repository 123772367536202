.comisionContainer{
   padding: 10px;
   color: #0C135A;
   display: flex;
   justify-content: center;
   flex-direction: column;
}
.comisionContainer .comih2{
   padding-bottom: 1rem;
}

 .comisionContainer h4, .comisionContainer p{
     background-color: rgba(0,0,0, .1);
     width: 100%;
     max-width: 800px;
     margin: 4px auto;
     font-weight: bolder;
 }
 
.contactoContainer{
   padding: 10px;
}
.contactoh1{
   color: #0C135A;
}
.contactocss{
   color:#0C135A;
   
}
.contactocss ul {
   list-style: none;
   font-weight: bold;
}

.headers{
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0 25px;
   padding-top: 5px ; 
   flex-wrap: wrap;

}
.headertext a h5{
   text-align: left;
   padding-left: 20px;
   padding-bottom: 5px;
   margin-bottom: 0px;
   color: black;
   font-weight: bold;
   text-decoration: none;
}
.head{
   background:#d3d3dd ;
}
.headertext{

   cursor: pointer;
   transition-duration: 0.4s;
}
.headertext:hover{
   color: orangered;
}
.headertext2{
   text-align: left;
   padding-left: 20px;
   color: black;
   padding-bottom: 5px;
}
.container {
   /*background: beige;*/
   display: grid;
   gap: 10px;
   grid-template-areas: "head head head head head menu"
                        "main main main main main  main"
                        "foot foot foot aside aside aside"
                        "article article article article article article";
 
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                  
                        grid-auto-rows: auto;             
 }
 /*areas*/
 .item-1 { grid-area: head; background:  black; }
 .item-2 { grid-area: menu; background: red; }
 .item-3 { grid-area: main; background: green; }
 .item-4 { grid-area: foot; background: orange; }
 .item-5 { grid-area: aside; background: olivedrab; }
 .item-6 { grid-area: article; background: indigo; }

/*margenes*/
.item-1{margin-top: 10px;}
.item-2{margin-top: 10px;}
.item-3{ padding: 10px;}
.item-5{ padding: 10px;}
.item-6{ margin-bottom: 10px;}
/*centrado*/
 .item-1{
   display: flex;
   justify-content: center;
   flex-direction: column;
 }

 .item-3 {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.item-3 h1, .item-3 p{
    background-color: rgba(0,0,0, .1);
    width: 100%;
    max-width: 800px;
    margin: 10px auto;
}
.item-3 h1{
  text-align: center;
 }
 .item-3 p{
  text-align: justify;
 }

 .item-4{
  display: flex;
  justify-content: center;
}
.item-4 img{
  width: 100%;
    max-width: 400px;
}

.item-5{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.item-5 h3, .item-5 p{
  background-color: rgba(0,0,0, .1);
    width: 100%;
    max-width: 600px;
    margin: 10px auto;
}
.item-5 h3{
  text-align: center;
 }
 .item-5 p{
  text-align: justify;
 }

 .item-6 img{
  width: 100%;
  max-width: 1100px;
  /*max-width: 500px;*/
}
  


 @media screen and (max-width: 780px){
   
  body{
  
    background: yellow;
    
  }
  .container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-template-areas: 
      "head"
      "menu"
      "main"
      "foot"
      "aside"
      "article";

  }
.item-2{
  display: flex;
  justify-content: center;
}
  
}
.menuBg{
   background-color: #0C135A;
}
section{
   color: #fff;
   background-color: #d3d3dd;

}

#collasible-nav-dropdown, #basic-nav-dropdown, .menu_item{
   color:#fff !important;
 }
 
 #collasible-nav-dropdown:hover, #basic-nav-dropdown:hover, .menu_item:hover{
   color: #ff0000 !important;
 }